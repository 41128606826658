import { addPaymentTo } from "./DepositPayment";
import { Box, BoxHeading, CommonProps } from "./shared";
import {
  Checkout,
  OverpaymentRefund,
  PaidFee,
  PaymentStatus,
  UnavailableFee,
  UnpaidFee,
  WaivedFee,
} from "../PaymentEditor";
import { getFormToken, savePayment } from "../../../api";
import { ACCOUNT_KEY, AccountContext } from "../../../context";
import {
  allFinalPaymentFees,
  arrDelete,
  canBuyTpp,
  depositFee,
  FeeDO_Interlocks,
  FeeDO_Type,
  feeLabel,
  feePayment,
  formatCents,
  fullNameOrPlaceholder,
  grandTotalPaid,
  guestFee,
  hasGuestFee,
  initFeesByGuest,
  isFeeOwed,
  isFeePaid,
  isFeeWaived,
  isGuestAccountOwner,
  isPaidInFull,
  mapPaymentResponse,
  overpaymentRefunds,
  paymentLineItem,
  totalToPayNow,
  tppFee,
} from "data-model";
import {
  ExternalLinkOrText,
  PaymentForm,
  SVG,
  useModal,
} from "react-components";
import clsx from "clsx";
import { set } from "idb-keyval";
import { klona } from "klona/json";
import { FC, Fragment, useContext, useState } from "react";

// const dataCy = "stepper-final-payment";

const FinalPayment: FC<CommonProps> = ({
  booking,
  account,
  onClose,
  isUnlocked,
}) => {
  const { guests, id: bookingId, departedAt } = booking;
  const isLocked = !isUnlocked;

  const setModal = useModal();
  const [, setAccount] = useContext(AccountContext);
  const [fees, setFees] = useState(initFeesByGuest(guests));

  const paidInFull = isPaidInFull(booking, false);
  const total = paidInFull
    ? grandTotalPaid(guests)
    : totalToPayNow(fees, guests);
  const totalFormatted = formatCents(total);

  const handleFeeChange = (
    posIdx: number,
    feeType: FeeDO_Type,
    isAdded: boolean
  ) => {
    const newFees = klona(fees);
    const feesForThisGuest = newFees[posIdx];
    const guest = guests[posIdx];

    if (isAdded) {
      // When final/adjustments are added, auto-add the dependent fees.
      if (FeeDO_Interlocks.includes(feeType)) {
        for (const interlockedFee of FeeDO_Interlocks) {
          if (
            hasGuestFee(guest, interlockedFee) &&
            isFeeOwed(guestFee(guest, interlockedFee)) // just in case it was paid somehow (against the policy)
          ) {
            feesForThisGuest.push(interlockedFee);
          }
        }
      } else {
        feesForThisGuest.push(feeType);
      }
    } else {
      // When final/adjustments are removed, auto-remove the dependent fees.
      if (FeeDO_Interlocks.includes(feeType)) {
        for (const interlockedFee of FeeDO_Interlocks) {
          arrDelete(feesForThisGuest, interlockedFee);
        }
      } else {
        arrDelete(feesForThisGuest, feeType);
      }
    }

    setFees(newFees);
  };

  const handleCheckout = async () => {
    const { token } = await getFormToken(bookingId, fees);

    setModal({
      isOpen: true,
      className: "is-fullwidth",
      body: (
        <PaymentForm
          token={token}
          onClose={() => setModal({ isOpen: false, className: "", body: null })}
          onSuccess={async (res) => {
            const paymentReq = mapPaymentResponse(res);

            const payment = await savePayment(bookingId, {
              ...paymentReq,
              fees,
            });

            const updatedAccount = addPaymentTo(
              account,
              bookingId,
              payment,
              fees
            );

            await set(ACCOUNT_KEY, updatedAccount);

            setAccount(updatedAccount);

            onClose();
          }}
        />
      ),
    });
  };

  return (
    <Box>
      <BoxHeading
        className="margin-bottom-1"
        showPerson={isGuestAccountOwner(guests, account, 0)}
        isUnlocked={isUnlocked}
      >
        {fullNameOrPlaceholder(guests, account, 0)}
      </BoxHeading>

      <div className="is-flex">
        <div className="is-flex-1">
          {guests.map((guest, posIdx) => {
            const deposit = depositFee(guest);
            const tpp = tppFee(guest);
            const finals = allFinalPaymentFees(guest);
            const depositPayment = feePayment(deposit);
            const tppPayment = feePayment(tpp);
            const isFirst = posIdx === 0;

            return (
              <Fragment key={guest.id}>
                {posIdx !== 0 && (
                  <BoxHeading
                    className="margin-y-1"
                    showPerson={isGuestAccountOwner(guests, account, posIdx)}
                    isUnlocked={isUnlocked}
                  >
                    {fullNameOrPlaceholder(guests, account, posIdx)}
                  </BoxHeading>
                )}

                {depositPayment ? (
                  <PaidFee
                    className={clsx(!isFirst && "margin-top-1")}
                    title="Deposit"
                    total={deposit.total}
                    payment={depositPayment}
                  />
                ) : isFeeWaived(deposit) ? (
                  <WaivedFee className="margin-top-1" title="Deposit" />
                ) : (
                  <UnpaidFee
                    className={clsx(!isFirst && "margin-top-1")}
                    title="Deposit"
                    total={deposit.total}
                    isAdded={fees[posIdx].includes(FeeDO_Type.DEPOSIT)}
                    onChange={handleFeeChange}
                    positionalIdx={posIdx}
                    feeType={FeeDO_Type.DEPOSIT}
                    isLocked={isLocked}
                  />
                )}

                {tppPayment ? (
                  <PaidFee
                    className="margin-top-1"
                    title="Protection"
                    total={tpp.total}
                    payment={tppPayment}
                  />
                ) : isFeeWaived(tpp) ? (
                  <WaivedFee
                    className="margin-top-1"
                    title="Travel Protection"
                  />
                ) : canBuyTpp(guest, departedAt, false, isLocked) ? (
                  <UnpaidFee
                    className="margin-top-1"
                    title={
                      <span className="is-flex">
                        Travel Protection
                        <ExternalLinkOrText
                          isLink={isUnlocked}
                          href={`${process.env.WWW_ORIGIN}/caravan-travel-protection.pdf`}
                        >
                          <SVG
                            className="margin-left-1"
                            path={
                              isUnlocked
                                ? "/site/icon/question-circled-blue"
                                : "/site/icon/question-circled-gray"
                            }
                            alt="Info"
                            height={16}
                          />
                        </ExternalLinkOrText>
                      </span>
                    }
                    total={tpp.total}
                    isAdded={fees[posIdx].includes(FeeDO_Type.TPP)}
                    onChange={handleFeeChange}
                    positionalIdx={posIdx}
                    feeType={FeeDO_Type.TPP}
                    isLocked={isLocked}
                  />
                ) : (
                  <UnavailableFee className="margin-top-1" title="Protection" />
                )}

                {finals.map((fpFee) => {
                  const fpPayment = feePayment(fpFee);
                  return (
                    <Fragment key={fpFee.type}>
                      {fpPayment ? (
                        <PaidFee
                          className="margin-top-1"
                          title={feeLabel(fpFee.type, true)}
                          total={paymentLineItem(fpFee, fpPayment).total}
                          payment={fpPayment}
                        />
                      ) : isFeeWaived(fpFee) ? (
                        <WaivedFee
                          className="margin-top-1"
                          title={feeLabel(fpFee.type)}
                        />
                      ) : (
                        <UnpaidFee
                          className="margin-top-1"
                          title={feeLabel(fpFee.type)}
                          total={fpFee.total}
                          isAdded={fees[posIdx].includes(fpFee.type)}
                          onChange={handleFeeChange}
                          positionalIdx={posIdx}
                          feeType={fpFee.type}
                          isLocked={isLocked}
                        />
                      )}
                      {overpaymentRefunds(fpFee, fpPayment).map((item) => (
                        <OverpaymentRefund
                          key={item.refund.id}
                          className="margin-top-1"
                          item={item}
                          payment={fpPayment!}
                        />
                      ))}
                    </Fragment>
                  );
                })}
              </Fragment>
            );
          })}
        </div>
        <aside className="payment-column margin-left-1 has-border-gray is-rounded-small padding-left-1 padding-right-2 is-flex-column is-justify-content-space-between">
          {guests.map((guest, posIdx) => {
            const isDepositAdded = fees[posIdx].includes(FeeDO_Type.DEPOSIT);
            const isTppAdded = fees[posIdx].includes(FeeDO_Type.TPP);
            const deposit = depositFee(guest);
            const tpp = tppFee(guest);
            const finals = allFinalPaymentFees(guest);
            return (
              <div key={posIdx} className="is-size-2">
                <PaymentStatus
                  isFeeAdded={isDepositAdded}
                  isFeePaid={isFeePaid(deposit)}
                  isFeeWaived={isFeeWaived(deposit)}
                  total={deposit.total}
                />
                <PaymentStatus
                  className="margin-top-1"
                  isFeeAdded={isTppAdded}
                  isFeeAvailable={canBuyTpp(guest, departedAt, false, isLocked)}
                  isFeePaid={isFeePaid(tpp)}
                  isFeeWaived={isFeeWaived(tpp)}
                  total={tpp.total}
                />
                {finals.map((fpFee) => {
                  const fpPayment = feePayment(fpFee);
                  return (
                    <Fragment key={fpFee.type}>
                      <PaymentStatus
                        className="margin-top-1"
                        isFeeAdded={fees[posIdx].includes(fpFee.type)}
                        isFeePaid={!!fpPayment}
                        isFeeWaived={isFeeWaived(fpFee)}
                        total={fpFee.total}
                      />
                      {overpaymentRefunds(fpFee, fpPayment).map((item) => (
                        <PaymentStatus
                          key={item.refund.id}
                          className="margin-top-1"
                          isFeeAvailable={false}
                          isFeeAdded={false}
                          isFeePaid={false}
                          isFeeWaived={false}
                          total={0}
                        />
                      ))}
                    </Fragment>
                  );
                })}
              </div>
            );
          })}
        </aside>
      </div>

      <Checkout
        hasUnpaidFees={!paidInFull}
        isLocked={isLocked}
        onCheckout={handleCheckout}
        total={total}
        totalFormatted={totalFormatted}
      />
    </Box>
  );
};

export { FinalPayment };
