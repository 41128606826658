import { MMM_d_yyyy, PaymentDO, RefundLineItemDO } from "data-model";
import { FC } from "react";
import clsx from "clsx";
import { DateTime } from "luxon";

const baseClassName =
  "payment-row is-marginless is-flex is-justify-content-space-between is-align-items-center has-text-green is-line-height-small";

interface Props {
  className?: string;
  title: string;
  total: number;
  payment: PaymentDO;
}

const PaidFee: FC<Props> = ({ className, title, total, payment }) => (
  <p className={clsx(baseClassName, className)}>
    <span className="payment-item">{title}</span>

    <em>
      {DateTime.fromISO(payment.submittedAt).toFormat(MMM_d_yyyy)} (...
      {payment.cardLastFour})
    </em>

    <em className="payment-amount has-text-right">{total / 100}</em>
  </p>
);

export { PaidFee };

const WaivedFee: FC<Pick<Props, "className" | "title">> = ({
  className,
  title,
}) => (
  <p className={clsx(baseClassName, className)}>
    <span className="payment-item">{title}</span>
    <span className="payment-amount has-text-right">0</span>
  </p>
);

export { WaivedFee };

interface OverpaymentRefundProps {
  className?: string;
  item: RefundLineItemDO;
  payment: PaymentDO;
}

const OverpaymentRefund: FC<OverpaymentRefundProps> = ({
  className,
  item,
  payment,
}) => (
  <p className={clsx(baseClassName, className)}>
    <span className="payment-item">Refund</span>

    <em>
      {item.refund.submittedAt ? (
        <>
          {DateTime.fromISO(item.refund.submittedAt).toFormat(MMM_d_yyyy)} (...
          {payment.cardLastFour})
        </>
      ) : (
        "Processing..."
      )}
    </em>

    <em className="payment-amount has-text-right">-{item.total / 100}</em>
  </p>
);

export { OverpaymentRefund };
